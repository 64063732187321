<template>
  <div>
    <ag-grid-vue
      style="width: 100%; height: 450px"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :gridOptions="gridOptions"
      :rowSelection="rowSelection"
      suppressDragLeaveHidesColumns
      @grid-ready="onGridReady"
      checkboxSelection="true"
      suppressMovableColumns
    >
    </ag-grid-vue>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import assetImg from "../../../components/Table/components/assetImg.vue";
import btnStatus from "../../../components/Table/components/btnStatus.vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      columnDefs: null,
      rowSelection: "multiple",
      gridApi: null,
      gridOptions: {
        defaultColDef: {
          resizable: true,
        },
      },
    };
  },
  props: {
    dataTable: [],
    tag: { type: Boolean, default: false },
    otherColumn: [],
    page: { type: Number },
    perPage: { type: Number },
  },
  watch: {
    // rowData() {
    //   this.setColumn();
    // },
  },
  beforeMount() {
    this.setColumn();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
  computed: {
    ...mapGetters({
      isRoleLower: "isRoleLower",
      permissionStatus: "permissionStatus",
    }),
    rowData() {
      return this.dataTable.map((asset, index) => {
        return {
          index: (this.page - 1) * this.perPage + index + 1,
          id_fixed_asset: asset.id_fixed_asset,
          edit: this.editTranslate(asset.type),
          from: asset.prior_depreciation_status,
          to: asset.depreciation_status,
          requested_by: asset.requested_user,
          confirmed_by: asset.confirmed_user,
          action_date: this.momentDay(asset.confirmed_date_time),
        };
      });
    },
  },
  methods: {
    editTranslate(data) {
      switch (data) {
        case "เปลี่ยนสถานะทางบัญชี":
          return this.$t("depreciation.change_acc_status");
        default: {
          return data;
        }
      }
    },
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
    },
    momentDay(date) {
      return moment(date).isValid
        ? moment(date).format("DD/MM/YYYY - HH.mm")
        : null;
    },
    setColumn() {
      this.columnDefs = [
        {
          headerName: this.$t("count.order"),
          field: "index",
          maxWidth: 110,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("home.fixedas_num"),
          field: "id_fixed_asset",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("depreciation.edit"),
          field: "edit",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("depreciation.from"),
          field: "from",
          sortable: true,
          minWidth: 200,
          cellRenderer: (params) => {
            const str = params.value;
            const str2 = str.charAt(0).toUpperCase() + str.slice(1);
            return str2;
          },
          cellClass: "text-center",
        },
        {
          headerName: this.$t("depreciation.to"),
          field: "to",
          sortable: true,
          minWidth: 200,
          cellRenderer: (params) => {
            const str = params.value;
            const str2 = str.charAt(0).toUpperCase() + str.slice(1);
            return str2;
          },
          cellClass: "text-center",
        },
        {
          headerName: this.$t("depreciation.request_by"),
          field: "requested_by",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("depreciation.confirm_by"),
          field: "confirmed_by",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("depreciation.action_date"),
          field: "action_date",
          sortable: true,
          minWidth: 200,
          cellClass: "text-center",
        },
      ];
    },
  },
  components: {
    AgGridVue,
    btnStatus,
    assetImg,
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
