<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <!-- Export Modal -->
    <b-modal
      class="p-2"
      id="export-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-modal')"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto">{{ $t("asset.choose_ex") }}</p>
      <b-row align-h="center">
        <!-- <button class="px-2 email-me" @click="onExport(true)">
          {{ $t("btn.send_mail") }}
        </button> -->

        <button class="px-2 download-file" @click="onExport(false)">
          {{ $t("btn.dl_file") }}
        </button>
      </b-row>
    </b-modal>

    <div class="manage-accounting my-3" style="padding: 0.4em">
      <div class="main">
        <p class="company-name">{{ companyName }}</p>
        <div
          class="menu d-flex flex-wrap align-items-center justify-content-between my-3"
        >
          <div class="d-flex align-items-center flex-wrap">
            <button
              v-if="!isNativeApp"
              class="btn-outline primary-btn me-3 d-flex my-1"
              @click="$bvModal.show('export-modal')"
            >
              <b-icon-upload font-scale="1.5" style="margin-right: 5px" />
              <p>{{ $t("depreciation.export_file") }}</p>
            </button>
          </div>
        </div>
        <HistoryTable
          :dataTable="assetList"
          :page="currentPage"
          :perPage="perPage"
        />
        <div class="d-flex justify-content-center mt-3">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
            @change="loadHistoryData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import axios from "axios";
import { vpsUrl, baseUrl } from "../../util/backend";
import { authHeader } from "@/store/actions";
import moment from "moment";
import HistoryTable from "../../components/Table/Depreciation/HistoryTable.vue";
import { mapGetters } from "vuex";
export default {
  name: "DepreciationReport",
  components: {
    HistoryTable,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      company_id: null,
      currentPage: 1,
      totalRows: 1,
      perPage: 50,
      assetList: [],
    };
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "";
    },
  },
  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
      companyName: "companyName",
    }),
  },
  methods: {
    async onExport(isMail) {
      this.isLoading = true;
      try {
        const filename = `${this.$t("depreciation.depreciation_history")}_${
          this.companyName
        }_${moment().format("DD-MM-YYYY_HH-mm")}.xlsx`;
        const res = await axios.get(
          `${baseUrl()}asset/getlogdepreciation/${this.company_id}/download`,
          {
            ...authHeader(),
            responseType: "blob",
          }
        );
        if (!isMail) {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          const elink = document.createElement("a");
          elink.download = filename;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        }
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },

    async loadHistoryData() {
      this.isLoading = true;
      this.company_id = await this.$store.dispatch("getCompanyIdCookie");
      try {
        const res = await axios.get(
          `${baseUrl()}asset/getlogdepreciation/${this.company_id}`,
          {
            params: {
              page: this.currentPage,
              limit: this.perPage,
            },
            ...authHeader(),
          }
        );
        const resData = res.data;
        this.assetList = resData.data;
        this.totalRows = resData.data.count;
      } catch (err) {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          console.log(err.message);
        }
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.loadHistoryData();
  },
};
</script>

<style lang="scss" scoped>
input,
select {
  height: 45px;
}

.manage-accounting {
  text-align: left;
}

.company-name {
  font-size: 18px;
}

.btn-outline {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #007afe;
    border-color: #007afe;
  }
  &.danger-btn {
    color: #f74949;
    border-color: #f74949;
  }
}

.form-control {
  min-width: 200px;
  width: 200px;
}

.search-box {
  input {
    width: 200px;
    background-color: transparent !important;
    border-radius: 10px;
  }
  img {
    margin: 0 10px;
  }
  background-color: #e0e0e0 !important;
}

.input-arrow {
  user-select: none;
  cursor: pointer;
  min-width: 200px;
  width: 200px;
  height: 45px;
  background-color: #f4f4f4;
  padding: 0.375rem 0.75rem;
  color: #212529;
  border-radius: 0.25rem;
  background-image: url("../../assets/down-arrow-black.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 97% 50% !important;
  padding-right: 40px !important;
}

@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}
</style>
